import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  props: {},
  overrides: {
    MuiAppBar: {
      root: {
        paddingLeft: "30px",
        paddingRight: "30px"
      }
    }
  },
  palette: {
    // background: {
    //   default: '#FFFFFF',
    //   paper: '#EFEFEF'
    // },
    primary: {
      light: "#207b7c",
      main: "#112345",
      dark: "#112345",
      contrastText: "#FFFFFF"
    },
  },

  typography: {
    fontFamily: "Montserrat, sans-serif;",
    h1: {
      fontFamily: "Montserrat, sans-serif;",
      fontSize: "20px"

    },
    h2: {

    },
    h3: {

    },
    h4: {

    },
    h5: {

    },
    h6: {

    },
    subtitle1: {
      // fontSize: '24px',
    },
    subtitle2: {
      // fontSize: '24px',
    },
    body1: {
      fontSize: "20px",
    },
    body2: {
      fontSize: "16px"
    }
  }
});
